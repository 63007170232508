import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import airports from '../Data/AirportList.json';
import config from '../Configuration/APIConfig'
import axios from "axios";
import Select from 'react-select';
import colourOptions from '../Data/airlines.json';
const initialFieldValues = {
    groupBookingId: "00000000-0000-0000-0000-000000000000",
    adults: 1,
    kids: 0,
    infants: 0,
    remarks: "",
    preferredAirlines: "",
    preferredFare: 0,
    status: "NEW",
};
export default function GroupFlight() {
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [isOneWay, setIsOneWay] = useState(true);
    const [isDomestic, setIsDomestic] = useState('DOMESTIC');
    const [journeyType, setJourneyType] = useState("ONEWAY");
    const [locationFrom, setLocationFrom] = useState('HYD');
    const [locationTo, setLocationTo] = useState('BOM');
    const [displayFrom, setDisplayFrom] = useState('Hyderabad(HYD)');
    const [displayTo, setDisplayTo] = useState('Mumbai(BOM)');
    const [cityFrom, setCityFrom] = useState('Hyderabad');
    const [cityTo, setCityTo] = useState('Mumbai');
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [from, setFrom] = useState('Hyderabad, India');
    const [to, setTo] = useState('Mumbai, India');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [selectedOption, setSelectedOption] = useState(null);
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        temp.displayTo = displayTo === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleJourneyType = (e) => {
        if (e.target.value === "ROUNDTRIP") {
            setIsOneWay(false)
        }
        else {
            setIsOneWay(true)
        }
        setJourneyType(e.target.value)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    function getFlightRequests() {
        const fvalues = [];
        /* const getFlightRequest = {
            "groupBookingId": values.groupBookingId,
            "departureDate": moment(departureDate).format('YYYY-MM-DD'),
            "returnDate": moment(returnDate).format('YYYY-MM-DD'),
            "departureCode": locationFrom,
            "arrivalCode": locationTo,
        } */
        fvalues.push({ groupFlightId: values.groupFlightId, groupBookingId: values.groupBookingId, departureCode: locationFrom, arrivalCode: locationTo, departureDate: moment(departureDate).format('YYYY-MM-DD'), returnDate: moment(returnDate).format('YYYY-MM-DD') });
        return fvalues;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            const searchQuery = {
                "groupBookingId": values.groupBookingId,
                "userId": localStorage.getItem('userId'),
                "adults": values.adults,
                "kids": values.kids,
                "infants": values.infants,
                "remarks": values.remarks,
                "status": values.status,
                "preferredAirlines": selectedOption,
                "groupFlights": getFlightRequests()
            }
            addOrEdit(searchQuery)
        }
    }
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEGROUPBOOKING, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setDepartureDate(new Date());
        setReturnDate(new Date());
        setLocationFrom('HYD');
        setLocationTo('BOM');
        setDisplayFrom('Hyderabad(HYD)');
        setDisplayTo('Mumbai(BOM)');
        setCityFrom('Hyderabad');
        setCityTo('Mumbai');
    }
    const addOrEdit = (formData) => {
        applicationAPI()
            .create(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    setLoading(false);
                    alert("Request submitted successfully");
                    resetForm();
                    GetGroupBookings();
                }
                else {
                    setLoading(false);
                    alert(res.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
                alert("Error in saving data");
            });
    };
    const handleDepartureDate = (date) => {
        if (date !== null) {
            setDepartureDate(date)
            addDays(date)
        }
    }
    const handleReturnDate = (date) => {
        if (date !== null) {
            setReturnDate(date)
            addDays(date)
        }
    }
    const addDays = (departureDate) => {
        if (departureDate === undefined) {
            departureDate = new Date();
        }
        const newDate = new Date(departureDate.getTime());
        setReturnDate(newDate.setDate(departureDate.getDate()));
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const [departureResults, setDepartureResults] = useState([]);
    const [returnResults, setReturnResults] = useState([]);
    const inputDRef = useRef(null);
    const inputRRef = useRef(null);
    const handleDepartureAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayFrom(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setDepartureResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setDepartureResults(filteredAirports1);
            }
        } else {
            setDepartureResults([]);
        }
    };
    const handleDepartureAirportClick = (e) => {
        setReturnResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setDepartureResults(filteredAirports);
        inputDRef.current.select();
    };
    const handleDepartureSelect = (data) => {
        if (data.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        var DF = data.cityName + "(" + data.airportCode + ")";
        setDisplayFrom(DF);
        setLocationFrom(data.airportCode)
        setCityFrom(data.cityName);
        setFrom(data.cityName);
        setDepartureResults([]);
    }
    const handleReturnAirportSearch = (e) => {
        const value = e.target.value;
        setDisplayTo(value);
        if (value.length > 0) {
            const filteredAirports = airports
                .filter(airport =>
                    airport.airportCode.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setReturnResults(filteredAirports);
            if (filteredAirports.length === 0) {
                const filteredAirports1 = airports
                    .filter(airport =>
                        airport.cityName.toLowerCase().includes(value.toLowerCase())
                    )
                    .slice(0, 10);
                setReturnResults(filteredAirports1);
            }
        } else {
            setReturnResults([]);
        }
    };
    const handleReturnSelect = (data) => {
        var DF = data.cityName + "(" + data.airportCode + ")";
        if (data.countryCode !== "IN") {
            setIsDomestic('INTERNATIONAL')
        }
        setDisplayTo(DF);
        setLocationTo(data.airportCode)
        setCityTo(data.cityName);
        setTo(data.cityName);
        setReturnResults([]);
    }
    const handleReturnAirportClick = (e) => {
        setDepartureResults([]);
        let filteredAirports = airports.filter(function (p) {
            return p.display === true
        }).slice(0, 10);
        setReturnResults(filteredAirports);
        inputRRef.current.select();
    };
    const GetGroupBookings = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETUSERGROUPBOOKINGS + "?userId=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.statusCode == 200) {
                    if (response.data.data.data != null) {
                        setBookings(response.data.data.data);
                    }
                }
            });
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEGROUPBOOKING + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        alert(response.data.data);
                        GetGroupBookings();
                    }
                    else {
                        alert(response.data.data);
                        GetGroupBookings();
                    }
                });
        }
    }
    const options = colourOptions.map(item => ({
        value: item.name,
        label: item.name,
        logo: item.logo // Optional if needed elsewhere
    }));
    const handleAirlineChange = (options) => {
        const values = options.map(option => option.value).join(', ');
        setSelectedOption(values);  // Set the concatenated values
        console.log('Selected:', options);
    };
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
        else {
            localStorage.clear();
            navigate("/");
        }
        addDays(departureDate);
        GetGroupBookings();
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header>
            <div className="clearfix"></div>
            <section className="pt-3 gray-simple">
                <div className="container">
                    <div className="row">
                        {/* Breadcrumb */}
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Group Bookings</Link></li>
                                </ol>
                            </nav>
                        </div>
                        {/* Flight Info */}
                        <div className="col-12">
                            {/* Overview */}
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="fw-semibold mb-0">Group Booking Details</h6>
                                </div>
                                <div className="card-body">
                                    <div className="full-width d-flex flex-column mb-2 position-relative">
                                        <div className="row align-items-stat mb-5">
                                            <div className="flx-start mb-sm-0 mb-2">
                                                <div className="form-check form-check-inline">
                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="oneway" onChange={e => handleJourneyType(e)} value="ONEWAY" defaultChecked />
                                                    <label className="form-check-label text-dark" htmlFor="oneway">One Way</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="roundtrip" onChange={e => handleJourneyType(e)} value="ROUNDTRIP" />
                                                    <label className="form-check-label text-dark" htmlFor="return">Return</label>
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="row align-items-stat" style={{ marginBottom: "30px" }}>
                                                <div className="col-3">
                                                    <div className="form-group hdd-arrow mb-0">
                                                        <h6 className="text-dark">FROM</h6>
                                                        <div className="position-relative">
                                                            <input ref={inputDRef} className="form-control searchTextbox" type="text" placeholder="Search for an airport" value={displayFrom} onChange={handleDepartureAirportSearch} onClick={handleDepartureAirportClick} onFocus={handleDepartureAirportClick} />
                                                            <span className="icon-inside">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} fill="#808080" viewBox="0 0 20 17"><path d="M1.376 16.498v-1h17v1h-17zm1.575-5.075L.676 7.498l.85-.2 1.8 1.55 5.4-1.45-4.05-6.85L5.75.223l6.85 6.125 5.225-1.4c.383-.1.73-.03 1.038.212.308.242.462.554.462.938 0 .266-.075.5-.225.7-.15.2-.35.333-.6.4L2.95 11.423z" /></svg>
                                                            </span>
                                                            {errors.displayFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                                            {departureResults.length > 0 && (
                                                                <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                                    {departureResults.map((airport) => (
                                                                        <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleDepartureSelect(airport)}>
                                                                            <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                            <div className="item-details">
                                                                                <span className="item-code-date">{airport.airportName}</span>
                                                                                <span className="item-designation">{airport.countryName}</span>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-groupp hdd-arrow mb-0">
                                                        <h6 className="text-dark">TO</h6>
                                                        <div className="position-relative">
                                                            <input ref={inputRRef} className="form-control" type="text" placeholder="Search for an airport" value={displayTo} onChange={handleReturnAirportSearch} onClick={handleReturnAirportClick} onFocus={handleReturnAirportClick} />
                                                            <span className="icon-inside">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="#808080" viewBox="0 0 24 24"><mask id="mask0_160_1644" style={{ maskType: 'alpha' }} width={24} height={24} x={0} y={0} maskUnits="userSpaceOnUse"><path fill="#808080" d="M0 0H24V24H0z" /></mask><g mask="url(#mask0_160_1644)"><path d="M3.5 20.5v-1h17v1h-17zm15.475-5.1L3.5 11.125V6.7l.8.225L5 9.05l5.5 1.55V2.65l1.125.275 2.75 8.75 5.25 1.475c.267.067.48.209.638.425.158.217.237.459.237.725 0 .384-.162.692-.487.925-.325.234-.671.292-1.038.175z" /></g></svg>
                                                            </span>
                                                            {returnResults.length > 0 && (
                                                                <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
                                                                    {returnResults.map((airport) => (
                                                                        <li className="locationListVideoItem" key={airport.airportCode} onClick={() => handleReturnSelect(airport)}>
                                                                            <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                            <div className="item-details">
                                                                                <span className="item-code-date">{airport.airportName}</span>
                                                                                <span className="item-designation">{airport.countryName}</span>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                        {errors.displayTo === false ? (<div className="validationerror">Please select arrival </div>) : ('')}
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <h6 className="text-dark">DEPATURE DATE</h6>
                                                        <div className="position-relative">
                                                            <ReactDatePicker autoComplete="false" className="form-control" wrapperClassName="datePicker" name="departureDate" selected={departureDate} onChange={(date) => handleDepartureDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                                            <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                            {errors.departureDate === false ? (<div className="validationerror">Please select departureDate </div>) : ('')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <h6 className="text-dark">RETURN DATE</h6>
                                                    <div className="position-relative">
                                                        {isOneWay === true ?
                                                            <ReactDatePicker disabled autoComplete="false" className="form-control" wrapperClassName="datePicker" name="returnDate" placeholderText="Return Date" />
                                                            :
                                                            <ReactDatePicker autoComplete="false" className="form-control" wrapperClassName="datePicker" name="returnDate" selected={returnDate} onChange={(date) => { handleReturnDate(date) }} dateFormat="EEEE, dd MMM yyyy" minDate={departureDate} />
                                                        }
                                                        <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                        {errors.returnDate === false ? (<div className="validationerror">Please select returnDate </div>) : ('')}
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Adults</label>
                                                        <input type="number" min={1} onChange={handleInputChange} value={values.adults} name="adults" className="form-control" placeholder="1" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label>Kids</label>
                                                        <input type="number" min={0} onChange={handleInputChange} value={values.kids} name="kids" className="form-control" placeholder="0" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Infants</label>
                                                        <input type="number" min={0} onChange={handleInputChange} value={values.infants} name="infants" className="form-control" placeholder="0" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label">Airlines</label>
                                                    <Select
                                                        isMulti
                                                        name="colors"
                                                        options={options}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select airlines"
                                                        onChange={handleAirlineChange}
                                                        getOptionLabel={(e) => (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={e.logo} alt={e.label} style={{ width: 20, height: 20, marginRight: 10 }} />
                                                                {e.label}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Remarks</label>
                                                        <input type="text" value={values.remarks} onChange={handleInputChange} name="remarks" className="form-control" placeholder="Any Comments" />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                        <button className="btn fw-medium btn-primary full-width" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h4><i className="fa-solid fa-ticket me-2" />Group Bookings</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive table-card">
                                        <table className="table align-middle" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Destination</th>
                                                    <th>Travelling Dates</th>
                                                    <th>Travellers</th>
                                                    <th>Preferred</th>
                                                    <th>Created</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {bookings.length > 0 && bookings.map((v, index) =>
                                                    <tr key={v.groupBookingId}>
                                                        <td>{v.groupFlights.length > 0 ? v.groupFlights[0].departureCode + " - " + v.groupFlights[0].arrivalCode : ""}</td>
                                                        <td>{v.groupFlights.length > 0 ? moment(v.groupFlights[0].departureDate).format('Do MMM yyyy') : ""}</td>
                                                        <td>Adults: {v.adults}, Kids: {v.kids} Infants: {v.infants}</td>
                                                        <td>{v.preferredAirlines}</td>
                                                        <td>{moment(v.createdDate).format('Do MMM yyyy')}</td>
                                                        <td>{v.status}</td>
                                                        <td>
                                                            <Link className="btn btn-danger btn-sm" onClick={e => onDelete(e, v.groupBookingId)}>
                                                                DELETE
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
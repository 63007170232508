import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import airports from '../Data/AirportList.json';
import config from '../Configuration/APIConfig';
import axios from "axios";
import Select from 'react-select';
import colourOptions from '../Data/airlines.json';
const initialFieldValues = {
    groupBookingId: "00000000-0000-0000-0000-000000000000",
    adults: 1,
    kids: 0,
    infants: 0,
    remarks: "",
    preferredAirlines: "",
    preferredFare: 0,
    status: "NEW",
};
const GroupMulticityFlight = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState(initialFieldValues);
    const [trips, setTrips] = useState([
        { from: "", to: "", fromCode: "", toCode: "", departureDate: "", departureResults: [], arrivalResults: [] },
    ]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [selectedOption, setSelectedOption] = useState(null);
    const [bookings, setBookings] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const validate = () => {
        let temp = {}; // Temporary object to hold validation results

        // Iterate over trips array to validate each row
        trips.forEach((trip, index) => {
            temp[index] = {
                from: trip.from ? true : false, // Check if 'from' is not empty
                to: trip.to ? true : false,     // Check if 'to' is not empty
                departureDate: trip.departureDate ? true : false // Check if 'departureDate' is not empty
            };
        });

        // Check if any field in any trip is invalid
        const isValid = Object.values(temp).every((row) =>
            Object.values(row).every((x) => x === true)
        );

        setErrors(temp); // Save errors to state for displaying validation messages
        return isValid;
    };
    function getFlightRequests() {
        const fvalues = [];
        for (var i = 0; i < trips.length; i++) {
            fvalues.push({ groupFlightId: values.groupBookingId, groupBookingId: values.groupBookingId, departureCode: trips[i].fromCode, arrivalCode: trips[i].toCode, departureDate: moment(trips[i].departureDate).format('YYYY-MM-DD'), returnDate: moment(trips[i].departureDate).format('YYYY-MM-DD') });
        }
        return fvalues;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            const searchQuery = {
                "groupBookingId": values.groupBookingId,
                "userId": localStorage.getItem('userId'),
                "adults": values.adults,
                "kids": values.kids,
                "infants": values.infants,
                "remarks": values.remarks,
                "status": values.status,
                "preferredAirlines": selectedOption,
                "journeyType": "MULTICITY",
                "groupFlights": getFlightRequests()
            }
            addOrEdit(searchQuery)
        }
        else {
            alert("Please check the mandatory fields")
        }
    }
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEGROUPBOOKING, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setTrips([{ from: "", to: "", fromCode: "", toCode: "", departureDate: "", departureResults: [], arrivalResults: [] }])
        }
    const addOrEdit = (formData) => {
        applicationAPI()
            .create(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    setLoading(false);
                    alert("Request submitted successfully");
                    resetForm();
                    GetGroupBookings();
                }
                else {
                    setLoading(false);
                    alert(res.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
                alert("Error in saving data");
            });
    };
    const handleJourneyType = (e) => {
        console.log(e.target.value)
        if (e.target.value !== "MULTICITY") {
            navigate('/groupflights')
        }
    }
    const handleSearchInputChange = (index, field, value) => {
        const updatedTrips = [...trips];
        updatedTrips[index][field] = value;
        setTrips(updatedTrips);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleDepartureAirportSearch = (index, value) => {
        const filteredAirports = airports.filter((airport) =>
            airport.airportCode.toLowerCase().includes(value.toLowerCase())
        );

        const updatedTrips = [...trips];
        updatedTrips[index].departureResults = filteredAirports;
        updatedTrips[index].from = value;
        updatedTrips[index].fromCode = value;
        setTrips(updatedTrips);
    };
    const handleDepartureAirportClick = (index) => {
        const updatedTrips = [...trips];
        updatedTrips[index].departureResults = airports;
        setTrips(updatedTrips);
    };
    const handleDepartureSelect = (index, airport) => {
        const updatedTrips = [...trips];
        updatedTrips[index].from = `${airport.cityName} (${airport.airportCode})`;
        updatedTrips[index].fromCode = `${airport.airportCode}`;
        updatedTrips[index].departureResults = [];
        setTrips(updatedTrips);
    };
    const handleArrivalAirportSearch = (index, value) => {
        const filteredAirports = airports.filter((airport) =>
            airport.airportCode.toLowerCase().includes(value.toLowerCase())
        );
        const updatedTrips = [...trips];
        updatedTrips[index].arrivalResults = filteredAirports;
        updatedTrips[index].to = value;
        updatedTrips[index].toCode = value;
        setTrips(updatedTrips);
    };
    const handleArrivalAirportClick = (index) => {
        const updatedTrips = [...trips];
        updatedTrips[index].arrivalResults = airports;
        setTrips(updatedTrips);
    };

    const handleArrivalSelect = (index, airport) => {
        const updatedTrips = [...trips];
        updatedTrips[index].to = `${airport.cityName} (${airport.airportCode})`;
        updatedTrips[index].toCode = `${airport.airportCode}`;
        updatedTrips[index].arrivalResults = [];
        setTrips(updatedTrips);
    };

    const addTrip = () => {
        setTrips([...trips, { from: "", to: "", departureDate: null, departureResults: [] }]);
    };

    const removeTrip = (index) => {
        const updatedTrips = trips.filter((_, i) => i !== index);
        setTrips(updatedTrips);
    };
    const options = colourOptions.map(item => ({
        value: item.name,
        label: item.name,
        logo: item.logo // Optional if needed elsewhere
    }));
    const handleAirlineChange = (options) => {
        const values = options.map(option => option.value).join(', ');
        setSelectedOption(values);  // Set the concatenated values
        console.log('Selected:', options);
    };
    const GetGroupBookings = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETUSERGROUPBOOKINGS + "?userId=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.statusCode == 200) {
                    if (response.data.data.data != null) {
                        setBookings(response.data.data.data);
                    }
                }
            });
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEGROUPBOOKING + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        alert(response.data.data);
                        GetGroupBookings();
                    }
                    else {
                        alert(response.data.data);
                        GetGroupBookings();
                    }
                });
        }
    }
    useEffect(() => {
        if (localStorage.getItem("userId") !== null) {
            setUserId(localStorage.getItem("userId"))
        }
        else {
            localStorage.clear();
            navigate("/");
        }
        GetGroupBookings();
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header>
            <div className="clearfix"></div>
            <section className="pt-3 gray-simple">
                <div className="container">
                    <div className="row">
                        {/* Breadcrumb */}
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/"} className="text-primary">Group Bookings</Link></li>
                                </ol>
                            </nav>
                        </div>
                        {/* Flight Info */}
                        <div className="col-12">
                            {/* Overview */}
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="fw-semibold mb-0">Group Booking Details</h6>
                                </div>
                                <div className="card-body">
                                    <div className="full-width d-flex flex-column mb-2 position-relative">
                                        <div className="row align-items-stat mb-5">
                                            <div className="flx-start mb-sm-0 mb-2">
                                                <div className="form-check form-check-inline">
                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="oneway" onChange={e => handleJourneyType(e)} value="ONEWAY" />
                                                    <label className="form-check-label text-dark" htmlFor="oneway">One Way</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="roundtrip" onChange={e => handleJourneyType(e)} value="ROUNDTRIP" />
                                                    <label className="form-check-label text-dark" htmlFor="return">Return</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="trip" id="multicity" onChange={e => handleJourneyType(e)} value="MULTICITY" defaultChecked />
                                                    <label className="form-check-label text-dark" htmlFor="return">MultiCity</label>
                                                </div>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="multicity-container">
                                                {trips.map((trip, index) => (
                                                    <div className="row mb-3" key={index}>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <h6 className="text-dark">FROM</h6>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Search for an airport"
                                                                    value={trip.from}
                                                                    onChange={(e) => handleDepartureAirportSearch(index, e.target.value)}
                                                                    onClick={() => handleDepartureAirportClick(index)}
                                                                />
                                                                {errors[index]?.from === false && (
                                                                    <div className="invalid-feedback">Please select a departure airport.</div>
                                                                )}
                                                                {trip.departureResults && trip.departureResults.length > 0 && (
                                                                    <ul className="locationListVideo scrollable-list">
                                                                        {trip.departureResults.map((airport) => (
                                                                            <li
                                                                                key={airport.airportCode}
                                                                                className="locationListVideoItem"
                                                                                onClick={() => handleDepartureSelect(index, airport)}
                                                                            >
                                                                                <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                                <div className="item-details">
                                                                                    <span className="item-code-date">{airport.airportName}</span>
                                                                                    <span className="item-designation">{airport.countryName}</span>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <h6 className="text-dark">TO</h6>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Search for an airport"
                                                                    value={trip.to}
                                                                    onChange={(e) => handleArrivalAirportSearch(index, e.target.value)}
                                                                    onClick={() => handleArrivalAirportClick(index)}
                                                                />
                                                                {errors[index]?.to === false && (
                                                                    <div className="invalid-feedback">Please select a arrival airport.</div>
                                                                )}
                                                                {trip.arrivalResults && trip.arrivalResults.length > 0 && (
                                                                    <ul className="locationListVideo scrollable-list">
                                                                        {trip.arrivalResults.map((airport) => (
                                                                            <li
                                                                                key={airport.airportCode}
                                                                                className="locationListVideoItem"
                                                                                onClick={() => handleArrivalSelect(index, airport)}
                                                                            >
                                                                                <div className="item-name">{airport.cityName} ({airport.airportCode})</div>
                                                                                <div className="item-details">
                                                                                    <span className="item-code-date">{airport.airportName}</span>
                                                                                    <span className="item-designation">{airport.countryName}</span>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <h6 className="text-dark">DEPATURE DATE</h6>
                                                                <ReactDatePicker
                                                                    className="form-control"
                                                                    selected={trip.departureDate}
                                                                    onChange={(date) => handleSearchInputChange(index, "departureDate", date)}
                                                                    dateFormat="EEEE, dd MMM yyyy"
                                                                    minDate={new Date()}
                                                                />
                                                                {errors[index]?.departureDate === false && (
                                                                    <div className="invalid-feedback">Please select a departure date.</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-3 d-flex align-items-center">
                                                            {index > 0 && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger me-2"
                                                                    onClick={() => removeTrip(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            )}
                                                            {index === trips.length - 1 && (
                                                                <button type="button" className="btn btn-sm btn-primary" onClick={addTrip}>
                                                                    Add Another City
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Adults</label>
                                                        <input type="number" min={1} onChange={handleInputChange} value={values.adults} name="adults" className="form-control" placeholder="1" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label>Kids</label>
                                                        <input type="number" min={0} onChange={handleInputChange} value={values.kids} name="kids" className="form-control" placeholder="0" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Infants</label>
                                                        <input type="number" min={0} onChange={handleInputChange} value={values.infants} name="infants" className="form-control" placeholder="0" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <label className="form-label">Airlines</label>
                                                    <Select
                                                        isMulti
                                                        name="colors"
                                                        options={options}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select airlines"
                                                        onChange={handleAirlineChange}
                                                        getOptionLabel={(e) => (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={e.logo} alt={e.label} style={{ width: 20, height: 20, marginRight: 10 }} />
                                                                {e.label}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Remarks</label>
                                                        <input type="text" value={values.remarks} onChange={handleInputChange} name="remarks" className="form-control" placeholder="Any Comments" />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                        {loading===false?
                                                        <button className="btn fw-medium btn-primary full-width" type="submit">Submit</button>
                                                        :
                                                        <button className="btn fw-medium btn-primary full-width" type="button" disabled>Loading...</button>
}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h4><i className="fa-solid fa-ticket me-2" />Group Bookings</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive table-card">
                                        <table className="table align-middle" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Destination</th>
                                                    <th>Travelling Dates</th>
                                                    <th>Travellers</th>
                                                    <th>Preferred</th>
                                                    <th>Created</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {bookings.length > 0 && bookings.map((v, index) =>
                                                    <tr key={v.groupBookingId}>
                                                        <td>
                                                            {v.groupFlights.length > 0
                                                                ? v.groupFlights.map(
                                                                    (flight, index) =>
                                                                        `${flight.departureCode} - ${flight.arrivalCode}${index < v.groupFlights.length - 1 ? ", " : ""
                                                                        }`
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td>{v.groupFlights.length > 0 ? moment(v.groupFlights[0].departureDate).format('Do MMM yyyy') : ""}</td>
                                                        <td>Adults: {v.adults}, Kids: {v.kids} Infants: {v.infants}</td>
                                                        <td>{v.preferredAirlines}</td>
                                                        <td>{moment(v.createdDate).format('Do MMM yyyy')}</td>
                                                        <td>{v.status}</td>
                                                        <td>
                                                            <Link className="btn btn-danger btn-sm" onClick={e => onDelete(e, v.groupBookingId)}>
                                                                DELETE
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default GroupMulticityFlight;
